import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const logoImg = require("../images/marcaPompeoDeMatos.png")

const TopMenuSocial = ({ siteTitle }) => (
  <div className="menuSocial">
    <div className="container">
      <nav className="navbar navbar-expand-md">
        <Link className="navbar-brand" to="/">
          <img className="img-logo img-fluid" src={logoImg} alt="pompeo de mattos" />
        </Link>
        <div className="collapse navbar-collapse">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <a
                href="https://www.instagram.com/pompeodemattospdt"
                target="_blank"
                rel="noopener noreferrer"
                className="nav-link"
                title="Instagram"
              >
                <i className="fab btn btn-outline-warning border-0">
                  {/* <FontAwesomeIcon icon={["fab", "instagram"]} /> */}
                </i>
              </a>
            </li>
            <li className="nav-item">
              <a
                href="https://www.facebook.com/pompeodemattospdt/"
                target="_blank"
                rel="noopener noreferrer"
                className="nav-link"
                title="Facebook"
              >
                <i className=" btn btn-outline-warning border-0">
                  {/* <FontAwesomeIcon icon={["fab", "facebook"]} /> */}
                </i>
              </a>
            </li>
            <li className="nav-item">
              <a
                href="https://twitter.com/PompeodeMattos"
                target="_blank"
                rel="noopener noreferrer"
                className="nav-link"
                title="Twitter"
              >
                <i className=" btn btn-outline-warning border-0">
                  {/* <FontAwesomeIcon icon={["fab", "twitter"]} /> */}
                </i>
              </a>
            </li>
            <li className="nav-item">
              <a
                href="https://www.flickr.com/photos/148467470@N06/"
                target="_blank"
                rel="noopener noreferrer"
                className="nav-link"
                title="Flickr"
              >
                <i className="btn btn-outline-warning border-0">
                  {/* <FontAwesomeIcon icon={["fab", "flickr"]} /> */}
                </i>
              </a>
            </li>
            <li className="nav-item">
              <a
                href="https://www.pdt.org.br"
                target="_blank"
                rel="noopener noreferrer"
                className="nav-link"
              >
                <i className="fab btn btn-outline-warning bg-light border-0">
                  <img
                    src={require("../images/pdt_marca.png")}
                    className="img-fluid img-pdt"
                    alt="pdt"
                  />
                </i>
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
)

TopMenuSocial.propTypes = {
  siteTitle: PropTypes.string,
}

TopMenuSocial.defaultProps = {
  siteTitle: ``,
}

export default TopMenuSocial
