import React from "react"
import "../../node_modules/bootstrap/dist/js/bootstrap"

const Footer = () => (
  <footer className="mt-3 bg-principal">
    <div className="container">
      <div className="row">
        <div id="contato" className="col-sm-12 col-md-4 col-lg-4 mx-auto pt-4">
          <h4 className="border-bottom">ESCRITÓRIO POLÍTICO</h4>
          <div className="">
            <p>
              Rua Riachuelo, 1038 Sala 1405, Centro Histórico <br /> CEP
              90010-272 - Porto Alegre/RS
            </p>
            <p className="mb-0">
              <strong>
                Fone: <a href="tel:555132251942">(051) 3225-1942</a>
              </strong>
            </p>
          </div>
        </div>
        <div id="sobre" className="col-sm-12 col-md-4 col-lg-4 pt-4 mx-auto">
          <h4 className="border-bottom">GABINETE</h4>
          <div className="">
            <p>
              Câmara dos Deputados Anexo IV, Gabinete 704 <br /> CEP 70160-900 -
              Brasília/DF
            </p>
            <p className="mb-0">
              <strong>
                {" "}
                Fone: <a href="tel:556132155704">(061) 3215-5704</a>{" "}
              </strong>
            </p>
          </div>
        </div>
      </div>
      <div
        id="sitelegis"
        className="row d-flex justify-content-center border-top"
      >
        <h4>2019 | Sitelegis</h4>
      </div>
    </div>
  </footer>
)

export default Footer
