/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"
import TopMenuSocial from "./top-menu-social"
import Helmet from "react-helmet"

import "../scss/index.scss"



const Layout = ({ data, children, biografia, bodyClass }) => {
  const site = data.allGhostSettings.edges[0].node;
    // const twitterUrl = site.twitter
    //     ? `https://twitter.com/${site.twitter.replace(/^@/, ``)}`
    //     : null;
    // const facebookUrl = site.facebook
    //     ? `https://www.facebook.com/${site.facebook.replace(/^\//, ``)}`
    //     : null;
        
  return (
    <>
      <Helmet>
        <html lang={site.lang} />
        <style type="text/css">{`${site.codeinjection_styles}`}</style>
        <body className={bodyClass} />
      </Helmet>
      <TopMenuSocial />
      <Header
        menuLinks={data.site.siteMetadata.menuLinks}
        siteTitle={data.site.siteMetadata.title}
        biografia={biografia}
      />
      <div className="container pt-3">
        <main>{children}</main>
      </div>
      <Footer />
    </>
  )
}

const LayoutQuery = (props) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        allGhostSettings {
          edges {
            node {
              ...GhostSettingsFields
            }
          }
        }
        site {
          siteMetadata {
            title
            menuLinks {
              name
              link
            }
          }
        }
      }
    `}
    render={(data) => <Layout data={data} {...props} />}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutQuery
