import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const NavLink = props => (
  <Link
    {...props}
    getProps={({ isCurrent, isPartiallyCurrent, href }) => {
      // https://reach.tech/router/api/Link

      if (href !== "/") {
        //corrigindo o problema de colocar a classe active no menu home pra todos os links
        return {
          className: isPartiallyCurrent
            ? "nav-item nav-link link-active"
            : "nav-item nav-link",
        }
      }
      return {
        className: isCurrent
          ? "nav-item nav-link link-active"
          : "nav-item nav-link",
      }
    }}
  />
)

const BiografiaMenu = ({ items }) => {
  const uniqueItems = Array.from(new Set(items.map(x => x.ano)))

  return (
    <div className="container">
      <div className="row">
        <hr className="w-100 p-0 mt-2 mb-1" />

        <ul className="list-inline col-md-12">
          <a name="#biografia" className="biografia-links" href={`#biografia`}>
            <li className="list-inline-item align-item-center">
              <span className="text-warning">| Biografia | </span>
            </li>
          </a>
          {uniqueItems.map((item, index) => (
            <a className="biografia-links" key={index} href={`#${item}`}>
              <li className="list-inline-item align-item-center">
                <span className="text-warning"> {item} | </span>
              </li>
            </a>
          ))}
        </ul>
      </div>
    </div>
  )
}

const Header = ({ siteTitle, menuLinks, biografia }) => (
  <nav className="navbar sticky-top navbar-expand-md navbar-dark menuItens d-flex flex-column align-items-end">
    <div className="container ">
      <Link className="navbar-brand" to="/" />
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavAltMarkup"
        aria-controls="navbarNavAltMarkup"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon" />
      </button>

      <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
        <div className="navbar-nav ml-auto">
          {menuLinks.map((route, index) => (
            <NavLink key={index} to={route.link}>
              {route.name}
            </NavLink>
          ))}
        </div>
      </div>
    </div>
    {biografia ? <BiografiaMenu items={biografia} /> : null}
  </nav>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
